import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/IndexPage',
    name: 'IndexPage',
    component: () => import(/* webpackChunkName: "about" */ '../page/IndexPage.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    children: [
      {
        path: '/StudentManagement',
        name: 'StudentManagement',
        component: () => import(/* webpackChunkName: "about" */ '../page/StudentManagement.vue'),
      },
      {
        path: '/TeacherManagement',
        name: 'TeacherManagement',
        component: () => import(/* webpackChunkName: "about" */ '../page/TeacherManagement.vue'),
      },
      {
        path: '/InvestorManagement',
        name: 'InvestorManagement',
        component: () => import(/* webpackChunkName: "about" */ '../page/InvestorManagement.vue'),
      },
      {
        path: '/ServiceManagement',
        name: 'ServiceManagement',
        component: () => import(/* webpackChunkName: "about" */ '../page/ServiceManagement.vue'),
      }
      ,
      {
        path: '/LearningImprovement',
        name: 'LearningImprovement',
        component: () => import(/* webpackChunkName: "about" */ '../page/LearningImprovement.vue'),
      }
      ,
      {
        path: '/ActivityImplementation',
        name: 'ActivityImplementation',
        component: () => import(/* webpackChunkName: "about" */ '../page/ActivityImplementation.vue'),
      }
      ,
      {
        path: '/ConsultationPairing',
        name: 'ConsultationPairing',
        component: () => import(/* webpackChunkName: "about" */ '../page/ConsultationPairing.vue'),
      }
      ,
      {
        path: '/FinancingConnection',
        name: 'FinancingConnection',
        component: () => import(/* webpackChunkName: "about" */ '../page/FinancingConnection.vue'),
      }
      ,
      {
        path: '/OpeningGuidance',
        name: 'OpeningGuidance',
        component: () => import(/* webpackChunkName: "about" */ '../page/OpeningGuidance.vue'),
      }
      ,
      {
        path: '/EntrepreneurshipIncubation',
        name: 'EntrepreneurshipIncubation',
        component: () => import(/* webpackChunkName: "about" */ '../page/EntrepreneurshipIncubation.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
